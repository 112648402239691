<template>
    <PageMainWrapper>
        <FormAlert />
        <PageHeader />
        <div class="page-main-slot">
            <slot />
        </div>
        <PageFooter />
        <PagePattern />
    </PageMainWrapper>
</template>

<style lang="scss">
.page-main-slot {
    grid-column: 1 / -1;
    position: relative;
    z-index: z('page-main-slot');

    @include fullbleed;
}
</style>
