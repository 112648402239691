<script setup lang="ts">
import Close from 'assets/icons/close.svg'

const errorAlert = useState('error')
const successAlert = useState('success')

/**
 *  Reset error
 */
const resetError = () => {
    errorAlert.value = null
}

// Hide success after 5 seconds
watch([successAlert], () => {
    if (successAlert.value) {
        setTimeout(() => {
            successAlert.value = null
        }, 3000)
    }
})
</script>

<template>
    <Transition name="fade" mode="out-in">
        <div v-if="errorAlert" class="form-alert form-error-alert" @click="resetError">
            {{ errorAlert?.label }} <Close />
        </div>
    </Transition>
    <Transition name="fade" mode="out-in">
        <div v-if="successAlert" class="form-alert form-success-alert">
            {{ successAlert?.label }}
        </div>
    </Transition>
</template>

<style lang="scss">
.form-alert {
    position: fixed;
    top: px2rem(40);
    z-index: z('form-alert');
    padding: px2rem(10);
    font-size: px2rem(14);
    border-radius: 10px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100vw - 60px);

    @include bp(medium1) {
        font-size: px2rem(18);
        padding: px2rem(20);
        width: auto;
    }

    svg {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        cursor: pointer;

        @include bp(medium1) {
            width: 16px;
            height: 16px;
            right: 20px;
        }
    }
}

.form-error-alert {
    background: $form-error-alert-bg;
    color: $form-error-alert;
    border: $form-error-alert-border 2px solid;
    cursor: pointer;
    padding-inline-end: 30px;

    @include bp(medium1) {
        padding-inline-end: 50px;
    }

    svg {
        fill: $form-error-alert;
    }
}

.form-success-alert {
    background: $form-success-alert-bg;
    color: $form-success-alert;
    border: $form-success-alert-border 2px solid;

    svg {
        fill: $form-success-alert;
    }
}
</style>
